import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import MyPage from '../components/my_page'

import MyImagesSingle from '../components/my_images_single'

const ApieMusPage = () => {
  const pageTitle = 'Apie mus'
  const pageSeoTitle =
    'MŪSŲ PĖDUTĖS – tai Vilniuje, šalia Pašilaičių ir Perkūnkiemio,' +
    ' veikiantis privatus vaikų darželis gryname ore, gamtos ramumoje,' +
    ' kuris priima vaikučius nuo 1 metukų.'
  const pageContent = (
    <span>
      <MyImagesSingle imgPublicId="musupedutes/kiti/evelina_agne" wrapLeft />
      <p>
        <em>MŪSŲ PĖDUTĖS</em> – tai Vilniuje, šalia Pašilaičių ir Perkūnkiemio,
        veikiantis privatus vaikų darželis gryname ore, gamtos ramumoje, kuris
        priima vaikučius nuo 1 metukų.{' '}
      </p>
      <p>
        Kiekvienas mažylis, kiekviena mama ir tėtis yra gamtos dalelės, drauge
        sudarančios vieną darnią gyvybės, augimo, gerovės struktūrą – šeimą.
        Kaip gamtoje nėra nieko bereikšmio, taip šeimoje – visos dalelės yra
        susijusios, viena kitai gyvybiškai svarbios. O visų svarbiausias yra
        pats mažiausias pumpurėlis. Nors žingsnis dar netvirtas, bet kiek jame
        gyvybės, jėgos, noro užaugti!
      </p>
      <p>
        <em>MŪSŲ PĖDUČIŲ</em> įkūrėjų, dviejų jaunų psichologių, akys švyti
        matant beribį mažojo žmogaus potencialą pažinti ir tobulėti. Ir jam net
        nereikia nieko nurodyti ar paliepti. Mažieji žmonės turi pačią geriausia
        intuiciją – jie patys žino, kaip, kada ir ką jiems reikia pažinti.
        Suaugusiųjų pareiga yra sukurti pažinimui tinkamą aplinką ir neužmiršti
        visą laiką būti šalia – jei kartais susipintų kojos... Nes kojos,
        pirmieji žingsniai ir mažų <em>PĖDUČIŲ</em> įspaudai pažinimo take yra
        tokie svarbūs.
      </p>
      <p>
        Darželio laikotarpio, pirmųjų šešerių vaiko vystymosi metų, svarba yra
        neabejotina. Tai viso gyvenimo pagrindas ir atskaitos taškas. Visas
        gėrybes, kurias surenka šiame amžiuje, vaikas naudoja tolimesniame
        gyvenime. Tad tikrai nėra kada švaistyti laiko paprastam buvimui.
        Mažajam žmogui reikia ne būti, o <em>AUGTI</em>!
      </p>
      <p>
        <em>MŪSŲ PĖDUTĖSE</em> tikime, kad geriausia padėjėja vaikui augti yra
        gamta. Didelį dėmesį skiriame darnai tarp mažojo žmogaus ir gamtos.
        Kruopščiai ir atsakingai kūrėme darželio ugdymo sampratą, derindami
        ugdymo su gamta, etnokultūrinio lavinimo ir psichologinių asmenybės
        raidos teorijų komponentus.
      </p>
      <p>
        {' '}
        <em>MŪSŲ PĖDUTĖSE</em> vaikai ne būna, o <em>AUGA</em>.
      </p>
    </span>
  )

  return (
    <MyPage
      pageType="simple"
      pageTitle={pageTitle}
      pageSeoTitle={pageSeoTitle}
      pageContent={pageContent}
    />
  )
}

export default ApieMusPage
